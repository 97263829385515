import { useAuthStore } from '~/stores/auth'
import { HostOfApi } from '~/constants/api'

export default function () {
  const authStore = useAuthStore()

  return $fetch.create({
    baseURL: HostOfApi,
    onRequest({ options }) {
      if (authStore.currentToken) {
        const headers = options.headers ||= {}
        if (Array.isArray(headers)) {
          headers.push(['Authorization', `Bearer ${authStore.currentToken}`])
        }
        else if (headers instanceof Headers) {
          headers.set('Authorization', `Bearer ${authStore.currentToken}`)
        }
        else {
          headers.Authorization = `Bearer ${authStore.currentToken}`
        }
      }
    },
    async onResponseError({ response }) {
      if (response.status === 401) {
        await navigateTo('/auth')
      }
    },
  })
}
