import ToastEventBus from 'primevue/toasteventbus'

export interface ToastMessageOptions {
  /**
     * Severity level of the message.
     * @defaultValue info
     */
  severity?: 'success' | 'info' | 'warn' | 'error' | 'secondary' | 'contrast' | undefined
  /**
     * Summary content of the message.
     */
  summary?: string | undefined
  /**
     * Detail content of the message.
     */
  detail?: unknown | undefined
  /**
     * Whether the message can be closed manually using the close icon.
     * @defaultValue true
     */
  closable?: boolean | undefined
  /**
     * Delay in milliseconds to close the message automatically.
     */
  life?: number | undefined
  /**
     * Key of the Toast to display the message.
     */
  group?: string | undefined
  /**
     * Style class of the message.
     */
  styleClass?: unknown
  /**
     * Style class of the content.
     */
  contentStyleClass?: unknown
}
const toast = {
  add: (message: ToastMessageOptions) => {
    if (message.life === undefined) {
      message.life = 3000
    }
    ToastEventBus.emit('add', message)
  },
  remove: (message: ToastMessageOptions) => {
    ToastEventBus.emit('remove', message)
  },
  removeGroup: (group: ToastMessageOptions) => {
    ToastEventBus.emit('remove-group', group)
  },
  removeAllGroups: () => {
    ToastEventBus.emit('remove-all-groups')
  },
}

export default toast
