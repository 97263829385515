// composables/use-consent.ts
export const giveConsent = (): void => {
  if (typeof window === 'undefined') {
    console.warn('giveConsent can only be called in the browser.')
    return
  }

  // Update consent in localStorage
  localStorage.setItem('user_consent', 'granted')

  // Trigger storage event manually to ensure synchronization across tabs
  window.dispatchEvent(new StorageEvent('storage', {
    key: 'user_consent',
    newValue: 'granted',
  }))
}

export const rejectConsent = (): void => {
  if (typeof window === 'undefined') {
    console.warn('rejectConsent can only be called in the browser.')
    return
  }

  // Update consent in localStorage
  localStorage.setItem('user_consent', 'denied')

  // Trigger storage event manually to ensure synchronization across tabs
  window.dispatchEvent(new StorageEvent('storage', {
    key: 'user_consent',
    newValue: 'denied',
  }))
}

export const hasConsent = (): boolean => {
  if (typeof window === 'undefined') {
    return false
  }
  return localStorage.getItem('user_consent') === 'granted'
}
