// stores/auth.ts
import { defineStore } from 'pinia'
import useAuthApi from '~/composables/use-auth-api'

/**
 * Types and Interfaces
 */

// Entity: Represents the state of the authentication module
type AuthState = {
  currentToken: string
  username: string
  isAuthenticated: boolean
  loading: boolean
}

// API Response Types
type LoginResponse = {
  token: string
}

type ValidateResponse = {
  isValid: boolean
}

type RegisterResponse = object

type CheckUserResponse = {
  exists: boolean
}

type ConfirmSignupResponse = {
  token: string
}

type ConfirmLoginResponse = {
  token: string
}

// Port: Interface defining the authentication API methods
interface IAuthApi {
  login(identifier: string, password: string): Promise<LoginResponse>
  validateToken(token: string): Promise<ValidateResponse>
  register(identifier: string, password: string): Promise<RegisterResponse>
  checkUser(identifier: string): Promise<CheckUserResponse>
  sendLoginLink(identifier: string): Promise<void>
  sendRegisterLink(identifier: string): Promise<void>
  confirmSignup(token: string): Promise<ConfirmSignupResponse>
  confirmLogin(token: string): Promise<ConfirmLoginResponse>
}

/**
 * Adapter: Implements the IAuthApi interface using the useAuthApi composable
 */
function createAuthApi(): IAuthApi {
  const authApi = useAuthApi()
  const basePath = '/api/v1/auth'

  return {
    login(identifier, password) {
      return authApi<LoginResponse>(`${basePath}`, {
        method: 'POST',
        body: { identifier, password },
      })
    },
    validateToken(token) {
      return authApi<ValidateResponse>(`${basePath}/validate-token`, {
        method: 'POST',
        body: { token },
      })
    },
    register(identifier, password) {
      return authApi<RegisterResponse>(`${basePath}/register`, {
        method: 'POST',
        body: { identifier, password },
      })
    },
    checkUser(identifier) {
      return authApi<CheckUserResponse>(`${basePath}/check-user`, {
        method: 'POST',
        body: { identifier },
      })
    },
    sendLoginLink(identifier) {
      return authApi(`${basePath}/email-only/login`, {
        method: 'POST',
        body: { identifier },
      })
    },
    sendRegisterLink(identifier) {
      return authApi(`${basePath}/email-only/register`, {
        method: 'POST',
        body: { identifier },
      })
    },
    confirmSignup(token) {
      return authApi<ConfirmSignupResponse>(`${basePath}/email-only/confirm-signup`, {
        method: 'POST',
        body: { token },
      })
    },
    confirmLogin(token) {
      return authApi<ConfirmLoginResponse>(`${basePath}/email-only/confirm-login`, {
        method: 'POST',
        body: { token },
      })
    },
  }
}

/**
 * Store: Uses the adapter and defines actions (use cases)
 */
export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    currentToken: '',
    username: '',
    isAuthenticated: false,
    loading: false,
  }),
  actions: {
    // Action: Login with username and password
    async login(identifier: string, password: string): Promise<boolean> {
      this.loading = true
      try {
        const authApi = createAuthApi()
        const data = await authApi.login(identifier, password)
        if (data.token) {
          this.currentToken = data.token
          this.username = identifier
          this.isAuthenticated = true
          this.setTokenOnStorage(data.token)
          return true
        }
        return false
      }
      catch (error) {
        console.error('Login error:', error)
        return false
      }
      finally {
        this.loading = false
      }
    },

    // Action: Register a new user with identifier and password
    async register(identifier: string, password: string): Promise<boolean> {
      this.loading = true
      try {
        const authApi = createAuthApi()
        await authApi.register(identifier, password)
        // Handle additional logic if needed
        return true
      }
      catch (error) {
        console.error('Register error:', error)
        return false
      }
      finally {
        this.loading = false
      }
    },

    // Action: Check if a user exists
    async checkUserExists(identifier: string): Promise<boolean> {
      this.loading = true
      try {
        const authApi = createAuthApi()
        const data = await authApi.checkUser(identifier)
        return data.exists
      }
      catch (error) {
        console.error('Check user error:', error)
        return false
      }
      finally {
        this.loading = false
      }
    },

    // Action: Validate the token from local storage
    async validateTokenFromStorage(): Promise<boolean> {
      const token = this.getTokenFromStorage()
      return this.validateToken(token)
    },

    // Action: Validate a token
    async validateToken(token: string): Promise<boolean> {
      if (token) {
        try {
          const authApi = createAuthApi()
          const data = await authApi.validateToken(token)
          if (!data.isValid) {
            this.logout()
            return false
          }
          this.currentToken = token
          this.isAuthenticated = true
          return true
        }
        catch (error) {
          console.error('Validate token error:', error)
          this.logout()
          return false
        }
      }
      return false
    },

    // Helper: Get token from local storage
    getTokenFromStorage(): string {
      return localStorage.getItem('authToken') || ''
    },

    // Helper: Set token in local storage
    setTokenOnStorage(token: string): void {
      localStorage.setItem('authToken', token)
    },

    // Action: Logout the user
    logout(): void {
      this.currentToken = ''
      this.username = ''
      this.isAuthenticated = false
      localStorage.removeItem('authToken')
    },

    // Action: Send a login link to the user's email
    async sendLoginLink(identifier: string): Promise<boolean> {
      this.loading = true
      try {
        const authApi = createAuthApi()
        await authApi.sendLoginLink(identifier)
        return true
      }
      catch (error) {
        console.error('Send login link error:', error)
        return false
      }
      finally {
        this.loading = false
      }
    },

    // Action: Send a registration link to the user's email
    async sendRegisterLink(identifier: string): Promise<boolean> {
      this.loading = true
      try {
        const authApi = createAuthApi()
        await authApi.sendRegisterLink(identifier)
        return true
      }
      catch (error) {
        console.error('Send register link error:', error)
        return false
      }
      finally {
        this.loading = false
      }
    },

    // Action: Confirm user signup with a token
    async confirmSignup(token: string): Promise<boolean> {
      this.loading = true
      try {
        const authApi = createAuthApi()
        const data = await authApi.confirmSignup(token)
        if (data.token) {
          this.currentToken = data.token
          this.isAuthenticated = true
          this.setTokenOnStorage(data.token)
          return true
        }
        return false
      }
      catch (error) {
        console.error('Confirm signup error:', error)
        return false
      }
      finally {
        this.loading = false
      }
    },

    // Action: Confirm user login with a token
    async confirmLogin(token: string): Promise<boolean> {
      this.loading = true
      try {
        const authApi = createAuthApi()
        const data = await authApi.confirmLogin(token)
        if (data.token) {
          this.currentToken = data.token
          this.isAuthenticated = true
          this.setTokenOnStorage(data.token)
          return true
        }
        return false
      }
      catch (error) {
        console.error('Confirm login error:', error)
        return false
      }
      finally {
        this.loading = false
      }
    },
  },
})
