import { default as functionalitykaQ6HSNrzdMeta } from "/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/about/functionality.vue?macro=true";
import { default as indexEHnL6mup37Meta } from "/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/about/index.vue?macro=true";
import { default as auth7ya0xPXjCgMeta } from "/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/auth.vue?macro=true";
import { default as billing_45historyhE8QRmCCxMMeta } from "/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/authenticated/billing-history.vue?macro=true";
import { default as chatpXBO8I3viyMeta } from "/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/authenticated/chat.vue?macro=true";
import { default as my_45accountbHUNGH1HyWMeta } from "/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/authenticated/my-account.vue?macro=true";
import { default as authenticated1wVZvgKCocMeta } from "/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/authenticated.vue?macro=true";
import { default as callbackEqLbzkLkOKMeta } from "/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/callback.vue?macro=true";
import { default as check_45your_45emailoNCqyBBuHWMeta } from "/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/check-your-email.vue?macro=true";
import { default as checkout45RSWv89mtMeta } from "/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/checkout.vue?macro=true";
import { default as indexcMMep3i02BMeta } from "/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/index.vue?macro=true";
import { default as policyiekA1trSTSMeta } from "/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/policy.vue?macro=true";
import { default as scketchd76nfwTyC3Meta } from "/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/scketch.vue?macro=true";
import { default as scratchaBxkfbjL1sMeta } from "/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/scratch.vue?macro=true";
import { default as subscription_45canceledmj6Xao3cIZMeta } from "/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/subscription-canceled.vue?macro=true";
import { default as subscription_45successIq2nivLUGCMeta } from "/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/subscription-success.vue?macro=true";
import { default as subscriptionsijl2Nn9aD4Meta } from "/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/subscriptions.vue?macro=true";
import { default as supporttBxVF17clxMeta } from "/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/support.vue?macro=true";
export default [
  {
    name: "about-functionality",
    path: "/about/functionality",
    component: () => import("/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/about/functionality.vue").then(m => m.default || m)
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "auth",
    path: "/auth",
    component: () => import("/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: "authenticated",
    path: "/authenticated",
    meta: authenticated1wVZvgKCocMeta || {},
    component: () => import("/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/authenticated.vue").then(m => m.default || m),
    children: [
  {
    name: "authenticated-billing-history",
    path: "billing-history",
    component: () => import("/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/authenticated/billing-history.vue").then(m => m.default || m)
  },
  {
    name: "authenticated-chat",
    path: "chat",
    component: () => import("/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/authenticated/chat.vue").then(m => m.default || m)
  },
  {
    name: "authenticated-my-account",
    path: "my-account",
    component: () => import("/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/authenticated/my-account.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "callback",
    path: "/callback",
    component: () => import("/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/callback.vue").then(m => m.default || m)
  },
  {
    name: "check-your-email",
    path: "/check-your-email",
    component: () => import("/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/check-your-email.vue").then(m => m.default || m)
  },
  {
    name: "checkout",
    path: "/checkout",
    component: () => import("/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "policy",
    path: "/policy",
    component: () => import("/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: "scketch",
    path: "/scketch",
    component: () => import("/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/scketch.vue").then(m => m.default || m)
  },
  {
    name: "scratch",
    path: "/scratch",
    component: () => import("/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/scratch.vue").then(m => m.default || m)
  },
  {
    name: "subscription-canceled",
    path: "/subscription-canceled",
    component: () => import("/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/subscription-canceled.vue").then(m => m.default || m)
  },
  {
    name: "subscription-success",
    path: "/subscription-success",
    component: () => import("/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/subscription-success.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions",
    path: "/subscriptions",
    component: () => import("/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: "support",
    path: "/support",
    component: () => import("/Users/taamavio/Documents/PersonalProjects/gitlab/odystechia/pages/support.vue").then(m => m.default || m)
  }
]