// plugins/google-analytics.js
export default defineNuxtPlugin(() => {
  if (import.meta.client) { // Correct client-side check
    console.info('Google Analytics plugin loaded')

    const GA_MEASUREMENT_ID = 'G-9DRK5N11EL' // Replace with your GA ID

    // Function to initialize GA
    const initializeGA = () => {
      // Prevent re-initialization if GA is already loaded
      if (window.gtagInitialized) return

      // Load the gtag.js script
      const script = document.createElement('script')
      script.async = true
      script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`
      document.head.appendChild(script)

      // Initialize dataLayer and gtag
      window.dataLayer = window.dataLayer || []
      function gtag(...args) {
        window.dataLayer.push(args)
      }
      window.gtag = gtag
      gtag('js', new Date())

      // Initialize GA with anonymize IP
      gtag('config', GA_MEASUREMENT_ID, { anonymize_ip: true })

      window.gtagInitialized = true
      console.info('GA Initialized with consent granted')
    }

    // Function to set default consent to denied
    const setDefaultConsent = () => {
      window.dataLayer = window.dataLayer || []
      function gtag(...args) {
        window.dataLayer.push(args)
      }
      window.gtag = gtag
      gtag('js', new Date())

      // Configure consent mode with default denied state
      gtag('consent', 'default', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
      })

      // Initialize GA with anonymize IP
      gtag('config', GA_MEASUREMENT_ID, { anonymize_ip: true })

      console.info('GA initialized with consent denied')
    }

    // Function to check and apply consent
    const applyConsent = () => {
      const consent = localStorage.getItem('user_consent')
      if (consent === 'granted') {
        initializeGA()
      }
      else if (consent === 'denied') {
        setDefaultConsent()
      }
      else {
        // If no consent found, default to denied
        setDefaultConsent()
      }
    }

    // Initialize consent on plugin load
    applyConsent()

    // Listen for changes in localStorage to synchronize consent across tabs
    const handleStorageChange = (event) => {
      if (event.key === 'user_consent') {
        const consent = event.newValue
        if (consent === 'granted') {
          initializeGA()
        }
        else if (consent === 'denied') {
          setDefaultConsent()
        }
      }
    }

    window.addEventListener('storage', handleStorageChange)

    // Optional: Cleanup listener when the component is unmounted
    // Note: Nuxt 3 plugins don't have an unmount lifecycle, so this is generally not necessary
  }
})
