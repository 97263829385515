<template>
  <div class="header-container">
    <div class="header">
      <nuxt-link
        class="nav-link"
        style="white-space: nowrap"
        to="/"
      >
        <base-logo size="40" />
      </nuxt-link>

      <nav :class="['squash-nav', { open: isOpen }]">
        <ul class="nav-list">
          <template v-if="authentication.isAuthenticated">
            <li class="nav-item">
              <nuxt-link
                class="nav-link"
                to="/authenticated/chat"
                @click="closeNav"
              >
                Chat
              </nuxt-link>
            </li>
            <prime-divider
              class="only-on-desktop"
              layout="vertical"
            />
            <prime-divider
              class="only-on-mobile"
              style="width: 80%"
            />
          </template>
          <li class="nav-item">
            <nuxt-link
              class="nav-link"
              to="/"
              @click="closeNav"
            >
              About
            </nuxt-link>
          </li>
          <li class="nav-item">
            <nuxt-link
              class="nav-link"
              to="/subscriptions"
              @click="closeNav"
            >
              Pricing
            </nuxt-link>
          </li>
          <li class="nav-item">
            <nuxt-link
              class="nav-link"
              to="/about/functionality"
              @click="closeNav"
            >
              How it works?
            </nuxt-link>
          </li>
        </ul>
      </nav>
      <div class="fill-mid" />
      <nav class="always-on-nav">
        <ul class="always-on-nav-list">
          <li class="nav-item minimize-container">
            <nuxt-link
              v-if="authentication.isAuthenticated"
              class="nav-link"
              style="white-space: nowrap"
              to="/authenticated/my-account"
              @click="closeNav"
            >
              <i class="pi pi-user" />
            </nuxt-link>
            <nuxt-link
              v-else
              class="nav-link"
              style="white-space: nowrap"
              to="/auth"
            >Sign
              In | Sign Up
            </nuxt-link>
          </li>
          <li class="nav-item minimize-container only-on-mobile">
            <button-hamburger
              :is-open="false"
              @click="toggleNav"
            />
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

const isOpen = ref(false)
const authentication = useAuthStore()

function toggleNav() {
  isOpen.value = !isOpen.value
}

function closeNav() {
  isOpen.value = false
}
</script>

<style lang="scss" scoped>
$tablet-breakpoint: 768px; /* Define a constant for the breakpoint*/

.header-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.header {
  padding: 10px 20px; /* Smaller padding for mobile*/
  display: flex;
  height: 5rem;
  width: 100%;

  @media (min-width: $tablet-breakpoint) {
    padding: 20px 40px; /* Larger padding for larger screens*/
  }
}

@media (min-width: 1080px) {
  .header {
    max-width: 1080px;
    width: 1080px;
    min-width: 1080px;
    padding: 20px 0;
  }
}

.glue-this-at-top-right {
  position: absolute;
  top: 10px;
  right: 10px;
}

.squash-nav {
  @media (max-width: $tablet-breakpoint) {
    z-index: 1000;
    position: fixed;
    top: 5rem;
    right: -100vw;
    transition: transform 500ms;
    width: 100vw;
    height: 100vh;
    background: white;

    &.open {
      transform: translate(-100vw, 0);
    }
  }

  & {
    display: flex;
    justify-content: space-between; /* Ensure nav items are spread out*/
    align-items: center;

    flex-direction: column; /* Stack items vertically on mobile*/
  }

  @media (min-width: $tablet-breakpoint) {
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}

.nav-list {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  flex-direction: column; /* Stack nav items vertically on mobile*/
  width: 100%; /* Full width to accommodate vertical stacking*/

  @media (min-width: $tablet-breakpoint) { /* Use the breakpoint variable*/
    flex-direction: row; /* Horizontal layout for larger screens*/
    width: fit-content;
  }
}

.always-on-nav {
  display: flex;
  align-items: center;
  width: 15rem;
  flex-direction: row;
  justify-content: flex-end;

  .always-on-nav-list {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    gap: 1rem;
    justify-content: flex-end;
    flex-direction: row; /* Horizontal layout for larger screens*/
    width: fit-content;
  }
}

.nav-item {
  margin: 10px 0; /* Increased vertical margin for mobile*/

  @media (min-width: $tablet-breakpoint) { /* Use the breakpoint variable*/
    margin: 0 20px; /* Horizontal spacing for larger screens*/
  }
}

.nav-link {
  color: #333;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px; /* Slightly smaller font for mobile*/

  @media (min-width: $tablet-breakpoint) { /* Use the breakpoint variable*/
    font-size: 16px; /* Larger font size for visibility on larger screens*/
  }
}

.fill-mid {
  display: block; /* Show only on larger screens*/
  flex: 1;
}

.minimize-container {
  width: fit-content;
  padding: 0;
  margin: 0;
}

.only-on-mobile {
  display: none; /* Hide on larger screens*/
  @media (max-width: $tablet-breakpoint) { /* Use the breakpoint variable*/
    display: block; /* Show only on mobile*/
  }
}

.only-on-desktop {
  display: none; /* Hide on larger screens*/
  @media (min-width: $tablet-breakpoint) { /* Use the breakpoint variable*/
    display: block; /* Show only on mobile*/
  }
}
</style>
