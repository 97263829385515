<script lang="ts" setup>
import { ref } from 'vue'
import type { NuxtError } from '#app'

const props = defineProps<{
  error: NuxtError
}>()

const statusCode = ref(props.error?.statusCode || 500)
const message = ref(props.error?.message || 'An unexpected error has occurred.')
</script>

<template>
  <div class="error-page">
    <h1>{{ statusCode }}</h1>
    <p>{{ message }}</p>
    <nuxt-link
      to="/"
      class="home-link"
    >
      Go back home
    </nuxt-link>
  </div>
</template>

<style scoped>
.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  color: #333;
}

.error-page h1 {
  font-size: 5rem;
  margin: 0;
}

.error-page p {
  font-size: 1.25rem;
  margin: 1rem 0;
}

.home-link {
  margin-top: 2rem;
  padding: 0.5rem 1rem;
  background-color: var(--p-primary-800);
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.home-link:hover {
  background-color: var(--p-primary-900);
}
</style>
