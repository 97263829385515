<!-- components/ModalConfirmation.vue -->
<template>
  <teleport to="body">
    <div
      class="fixed top-0 left-0 w-full h-full flex justify-content-center align-items-center z-5"
    >
      <div
        class="w-full h-full absolute bg-black-alpha-90"
        @click="close"
      />
      <div class="bg-white p-5 border-round z-5 w-20rem md:w-30rem">
        <h3>{{ title }}</h3>
        <p>{{ message }}</p>
        <div class="flex justify-content-end gap-2">
          <button
            class="btn btn-transparent"
            @click="close"
          >
            Cancel
          </button>
          <button
            class="btn btn-primary"
            @click="confirm"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue'

const props = defineProps<{
  title: string
  message: string
}>()

const emit = defineEmits(['confirm', 'close'])

const isVisible = ref(false)

watch(
  () => props.title,
  () => {
    if (props.title) isVisible.value = true
  },
)

function close() {
  isVisible.value = false
  emit('close')
}

function confirm() {
  emit('confirm')
  close()
}
</script>
