<!-- components/ModalService.vue -->
<template>
  <modal-confirmation
    v-for="modal in allCurrentModals"
    :key="modal.id"
    :title="modal.title"
    :message="modal.message"
    @confirm="confirm(modal)"
    @close="close(modal)"
  />
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted } from 'vue'
import useModalService from '~/composables/use-modal-service.ts'
import { isConfirmationEvent, type Modal } from '~/components/modal/types'

const allCurrentModals = ref<Modal[]>([])

function showConfirmation(evt?: unknown) {
  if (isConfirmationEvent(evt)) {
    allCurrentModals.value.push({
      id: `${Date.now()}`,
      title: evt.title,
      message: evt.message,
      handlers: evt.handlers,
    })
  }
  else {
    console.error('Invalid event passed to showConfirmation:', evt)
  }
}

function confirm(modal: Modal) {
  // Invoke the primary action
  modal.handlers.primary()
  // Remove the modal after confirming
  allCurrentModals.value = allCurrentModals.value.filter(m => m.id !== modal.id)
}

function close(modal: Modal) {
  // Invoke the secondary action if it exists
  if (modal.handlers.secondary) {
    modal.handlers.secondary()
  }
  // Remove the modal after closing
  allCurrentModals.value = allCurrentModals.value.filter(m => m.id !== modal.id)
}

onMounted(() => {
  useModalService.on('show-confirmation', showConfirmation)
})

onUnmounted(() => {
  useModalService.off('show-confirmation', showConfirmation)
})
</script>
