type EventHandler = (evt?: unknown) => void

export interface EventBusOptions {
  on(type: string, handler: EventHandler): this
  off(type: string, handler: EventHandler): this
  emit(type: string, evt?: unknown): void
  clear(): void
}

export function EventBus(): EventBusOptions {
  const allHandlers = new Map<string, EventHandler[]>()

  return {
    on(type: string, handler: EventHandler) {
      const handlers = allHandlers.get(type) || []
      handlers.push(handler)
      allHandlers.set(type, handlers)

      return this
    },

    off(type: string, handler: EventHandler) {
      const handlers = allHandlers.get(type)
      if (handlers) {
        const index = handlers.indexOf(handler)
        if (index > -1) {
          handlers.splice(index, 1)
        }
      }

      return this
    },

    emit(type: string, evt?: unknown) {
      const handlers = allHandlers.get(type)
      if (handlers) {
        handlers.forEach(handler => handler(evt))
      }
    },

    clear() {
      allHandlers.clear()
    },
  }
}
