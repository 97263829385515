function GetHostOfApi(): string {
  if (process.env.NODE_ENV === 'development') {
    console.log('Using local configuration')
    return 'http://localhost:8080'
  }
  else {
    return 'https://api.eventextractor.com'
  }
}

export const HostOfApi = GetHostOfApi()
