// stores/auth.ts

import { defineStore } from 'pinia'
import { useAuthStore } from './auth' // Ensure you have this import if not already present
import useEventExtractorApi from '~/composables/use-event-extractor-api'
import toast from '~/composables/use-toast-event-service'

type UserData = {
  identification: string
  plan: number
  planStartedAt: number
  targetLanguage: string
}

type AccountState = {
  userData: UserData
}

type UpdateUserDataPayload = {
  targetLanguage: string
}

export const useUserDataStore = defineStore('user-data-store', {
  state: (): AccountState => {
    return {
      userData: {
        identification: '',
        plan: 0, // Corrected from '' to 0
        planStartedAt: 0,
        targetLanguage: '', // Default language (e.g., 1 for English)
      },
    }
  },
  actions: {
    async syncStoreWithBackend(): Promise<void> {
      const api = useEventExtractorApi()
      try {
        this.userData = await api<UserData>('/api/v1/user/data')
        console.debug('User data synced with backend')
        console.debug(this.userData)
      }
      catch (error) {
        console.error('Failed to sync user data:', error)
        toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to sync user data',
        })
      }
    },

    /**
     * Deletes the user's account.
     */
    async deleteAccount(): Promise<void> {
      const api = useEventExtractorApi()
      try {
        await api('/api/v1/user_management/delete')
        toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Account deleted',
        })
        await navigateTo('/')
        useAuthStore().logout()
      }
      catch (error) {
        console.error('Failed to delete account:', error)
        toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to delete account',
        })
      }
    },

    /**
     * Cancels the user's subscription plan.
     */
    async cancelSubscription(): Promise<void> {
      const api = useEventExtractorApi()
      try {
        await api('/api/v1/user_management/cancel_plan')
        toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Subscription canceled',
        })
        await this.syncStoreWithBackend()
      }
      catch (error) {
        console.error('Failed to cancel subscription:', error)
        toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to cancel subscription',
        })
      }
    },

    /**
     * Updates the user's data, specifically the target language.
     * @param payload - The data to update.
     */
    async updateUserData(payload: UpdateUserDataPayload): Promise<void> {
      const api = useEventExtractorApi()
      try {
        await api('/api/v1/user/data', {
          method: 'POST',
          body: payload,
        })
        toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'User data updated successfully',
        })
        await this.syncStoreWithBackend()
      }
      catch (error) {
        console.error('Failed to update user data:', error)
        toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to update user data',
        })
      }
    },
  },
})
