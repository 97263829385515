<!-- components/ConsentBanner.vue -->
<template>
  <div
    v-if="showBanner"
    class="fixed bottom-0 left-0 w-full p-4 surface-50 shadow-lg flex flex-wrap justify-between items-center"
  >
    <div class="flex-1 text-gray-700">
      <p>
        We use cookies to enhance your experience. By continuing to visit this site you agree to our
        <nuxt-link
          to="/policy"
          class="text-blue-500 underline"
        >
          Privacy Policy
        </nuxt-link>.
      </p>
    </div>
    <div class="flex items-center mt-2 md:mt-0">
      <prime-button
        label="Accept"
        class="mr-2 fadein animation-duration-500 animation-iteration-2"
        @click="acceptConsent"
      />
      <prime-button
        label="Reject"
        severity="secondary"
        @click="rejectConsentHandler"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { giveConsent, rejectConsent } from '~/composables/use-consent'

const showBanner = ref(false)

onMounted(() => {
  const consent = localStorage.getItem('user_consent')
  if (!consent || consent === 'denied') {
    showBanner.value = true
  }
})

const acceptConsent = () => {
  giveConsent()
  showBanner.value = false
}

const rejectConsentHandler = () => {
  rejectConsent()
  showBanner.value = false
}
</script>
