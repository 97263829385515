export interface ModalHandlers {
  primary: () => void
  secondary?: () => void
}

export interface Modal {
  id: string
  title: string
  message: string
  handlers: ModalHandlers
}

export interface ConfirmationEvent {
  title: string
  message: string
  handlers: ModalHandlers
}

// Type guard to check if evt is of type ConfirmationEvent
export function isConfirmationEvent(evt: unknown): evt is ConfirmationEvent {
  return (
    evt
    && typeof evt.title === 'string'
    && typeof evt.message === 'string'
    && evt.handlers
    && typeof evt.handlers.primary === 'function'
    && (typeof evt.handlers.secondary === 'undefined' || typeof evt.handlers.secondary === 'function')
  )
}
