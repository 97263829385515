// stores/auth.ts
import { defineStore } from 'pinia'
import useEventExtractorApi from '~/composables/use-event-extractor-api'

type AccountState = {
  historyItems: HistoryItem[]
  remainingCredits: number
}

export interface HistoryItem {
  createdAt: number
  id: string
  response: ICalendarEvent
  text: string
  userIdentification: string
}
export interface AccountRemainingCreditsResponse {
  remainingCredits: number
}

export interface ICalendarEvent {
  description: string
  dtend: string
  dtstamp: string
  dtstart: string
  location: string
  organizer: string
  summary: string
  uid: string
}

export const useHistoryItemStore = defineStore('history-store', {
  state: (): AccountState => {
    const initialState: AccountState = {
      historyItems: [],
    }
    return initialState
  },
  actions: {
    async syncStoreWithBackend(): Promise<void> {
      const api = useEventExtractorApi()
      this.historyItems = await api<HistoryItem[]>('/api/v1/account/history/events')
      const response = await api<AccountRemainingCreditsResponse>('/api/v1/account/credits')
      this.remainingCredits = response.remainingCredits
    },
  },
})
