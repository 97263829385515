<template>
  <modal-service />
  <prime-toast />
  <base-header />
  <banner-consent />
  <div class="layout">
    <div class="layout__main">
      <nuxt-page />
    </div>
  </div>
</template>

<script lang="ts" setup>
import '~/assets/css/global.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import { useHistoryItemStore } from '~/stores/history'
import { useUserDataStore } from '~/stores/userData'

const auth = useAuthStore()
const historyItemStore = useHistoryItemStore()
const userDataStore = useUserDataStore()
onMounted(() => {
  console.log('App mounted')
  auth.validateTokenFromStorage().then(() => {
    if (auth.isAuthenticated) {
      historyItemStore.syncStoreWithBackend()
      userDataStore.syncStoreWithBackend()
    }
  })
})
</script>

<style lang="scss" scoped>
body {
 font-family: Arial, sans-serif;
 margin: 0;
 padding: 0;
}

.layout {
 /* Layout container to ensure full-width, centered content with responsive adjustments */
 min-width: 100%;
 display: flex;
 flex-direction: row;
 min-height: 100vh;
 justify-content: center;

 &__main {
  width: 100%;
  max-width: 100vw;
  margin: 0 16px;

  @media (min-width: 1080px) {
   max-width: 1080px;
   width: 1080px;
   min-width: 1080px;
   margin: 0;
   padding: 0;
  }
 }
}
</style>
